import React, { useContext } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Space,
  Table,
  Typography,
  Radio
} from "antd";
import {
  BulbTwoTone,
  HomeOutlined,
  InboxOutlined,
  PaperClipOutlined,
  SendOutlined,
  ThunderboltOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { useIntercoms } from "./useIntercoms";
import { ServicesList } from "./components/ServicesList";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { TableSearchInput } from "../../components/TableSearchInput";
import { useTranslation } from "react-i18next";
import type { ColumnsType } from 'antd/es/table';

export const Intercoms = () => {
  const {
    devices,
    checkedIDs,
    changeCheckedValue,
    changeSelectAll,
    showNotificationModal,
    showSliderModal,
    addNotifications,
    handleAddNotificationOnClick,
    handleAddNotificationCancel,
    areAllChecked,
    handleAddSliderCancel,
    handleAddSliderOnClick,
    handleAddPackageOnClick,
    handleDeletePackageOnClick,
    showPackageModal,
    showDeletePackageModal,
    handleAddPackageCancel,
    handleDeletePackageCancel,
    handleSelectedSlide,
    handleSelectedPackage,
    attachSlide,
    addPackageToIntercom,
    deletePackageFromIntercom,
    slides,
    packageId,
    packages,
    slideId,
    showDeleteSliderModal,
    handleDeleteSliderOnClick,
    handleDeleteSliderCancel,
    slidersFromIntercom,
    packagesFromIntercom,
    deleteSlideFromIntercom,
    setSelectedIntercom,
    handleAddPackageToFlatsOnClick,
    showPackageToFlatsModal,
    handleAddPackageToFlatsCancel,
    addPackageToAllFlats,
    isNotificationSending,
    isLoading,
    handleLinkServiceToIntercoms,
    isLinkServiceModalOpen,
    linkServiceToIntercom,
    toggleLinkServiceModal,
    services,
    setSelectedServices,
    setFilteredValue,
    filteredValue,
    isUnlinkServiceModalOpen,
    unlinkServiceFromIntercom,
    handleUnlinkServiceFromIntercoms,
    toggleUnlinkServiceModal,
    userInfoModal,
    handleUserInfoOnClick,
    handleUserInfoCancel,
    deviceInfo,
    paymentType,
    paymentTypeChange
  } = useIntercoms();

  const [form] = Form.useForm();
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  let onlineDevices = (deviceInfo['flats'] || []).filter((item) => {
    return item.active === true;
  });

  interface DataType {
    key: React.Key;
    motherboardId: string;
    address: any;
    isOnline: boolean;
  }

  const columns: ColumnsType<DataType> = [
    {
      dataIndex: "id",
      key: "checkbox",
      title: (
        <Checkbox onClick={() => changeSelectAll()} checked={areAllChecked} />
      ),
      render: (id, row) => {
        return (
          <Checkbox
            value={id}
            checked={checkedIDs.includes(id)}
            onClick={() => {
              changeCheckedValue(row, id);
              setSelectedIntercom(id);
            }}
          />
        );
      },
    },
    {
      title: t("MotherboardID"),
      dataIndex: "motherboardId",
      key: "motherboardId",
    },
    {
      title: t("Address"),
      key: "address",
      dataIndex: "address",
      // defaultSortOrder: 'ascend',
      render: (address) => {
        if (!address) {
          return "Not Activated";
        }
        return <div>{`${address.address}`}</div>;
      },
      sorter: (a, b) => {
        return a.address.address.localeCompare(b.address.address);
      },
    },
    {
      title: t("Online"),
      key: "isOnline",
      dataIndex: "isOnline",
      defaultSortOrder: 'ascend',
      render: (isOnline) => (
        <BulbTwoTone
          twoToneColor={isOnline === "true" ? "green" : "red"}
          style={{ fontSize: "20px" }}
        />
      ),
      sorter: (a: any, b: any) => {
        let value1: any = a.isOnline === "true" ? true : false;
        let value2: any = b.isOnline === "true" ? true : false;
        return value1 - value2;
      },
    },
    {
      title: t("Action"),
      key: "action",
      dataIndex: "id",
      render: (id, row) => {
        if (!row.address) {
          return (
            <Space size="middle">
              <Link
                className="disabled-link"
                to={{ pathname: `#`, state: { id: `${id}` } }}
              >
                {t("Details")}
              </Link>
            </Space>
          );
        }
        return (
          <Space size="middle">
            <Link to={{ pathname: `intercoms/${id}`, state: { id: `${id}` } }}>
              {t("Details")}
            </Link>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <Button
        onClick={handleAddNotificationOnClick}
        disabled={checkedIDs.length === 0}
        style={{ marginRight: 8 }}
        icon={<SendOutlined />}
      >
        {t("SendNotification")}
      </Button>
      <Button
        onClick={handleLinkServiceToIntercoms}
        disabled={checkedIDs.length === 0}
        style={{ marginRight: 8 }}
        icon={<ThunderboltOutlined />}
      >
        {t("LinkService")}
      </Button>
      <Button
        onClick={handleUnlinkServiceFromIntercoms}
        disabled={checkedIDs.length === 0}
        icon={<ThunderboltOutlined />}
      >
        {t("UnlinkService")}
      </Button>
      <Divider />
      <Button
        onClick={handleAddSliderOnClick}
        disabled={checkedIDs.length === 0}
        icon={<PaperClipOutlined />}
        style={{ marginRight: 8 }}
      >
        {t("AttachSlide")}
      </Button>
      <Button
        onClick={handleDeleteSliderOnClick}
        disabled={checkedIDs.length !== 1}
        danger
      >
        {t("RemoveSlide")}
      </Button>
      <Divider />
      {!auth.isSuperAdmin ? null : (
        <div>
          <Button
            onClick={handleAddPackageOnClick}
            disabled={checkedIDs.length !== 1}
            style={{ marginRight: 8 }}
            icon={<InboxOutlined />}
          >
            {t("AddPackageToIntercom")}
          </Button>
          <Button
            onClick={handleAddPackageToFlatsOnClick}
            disabled={checkedIDs.length !== 1}
            style={{ marginRight: 8 }}
            icon={<HomeOutlined />}
          >
            {t("AddPackageToAllFlats")}
          </Button>
          <Button
            onClick={handleDeletePackageOnClick}
            disabled={checkedIDs.length !== 1}
            danger
          >
            {t("RemovePackage")}
          </Button>
          <Divider />
        </div>
      )}
      {/* <Divider />
      <Button
        onClick={handleUserInfoOnClick}
        disabled={checkedIDs.length === 0}
        style={{ marginRight: 8 }}
        icon={<InfoCircleOutlined />}
      >
        Device Information
      </Button>
      <Divider /> */}

      <Modal
        title={t("AddNotification")}
        visible={showNotificationModal}
        onOk={form.submit}
        onCancel={handleAddNotificationCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={addNotifications}>
          <Form.Item
            label={t("Title")}
            name="title"
            rules={[{ required: true, message: "Please input the title!" }]}
            preserve
          >
            <Input type={"text"} />
          </Form.Item>
          <Form.Item
            label={t("Message")}
            name="body"
            rules={[{ required: true, message: "Please input the message!" }]}
            preserve
          >
            <Input.TextArea />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isNotificationSending}
            icon={<SendOutlined />}
          >
            {t("Send")}
          </Button>
        </Form>
      </Modal>

      <Modal
        title={t("RemoveSlide")}
        visible={showDeleteSliderModal}
        onOk={form.submit}
        onCancel={handleDeleteSliderCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        {slidersFromIntercom?.map((e) => {
          return (
            slidersFromIntercom?.length && (
              <Button
                key={e.id}
                onClick={() => handleSelectedSlide(e)}
                style={slideId === e.id
                  ? { width: "190px", height: "200px", margin: "20px", border: "3px solid #1677FF" }
                  : { width: "190px", height: "200px", margin: "20px" }
                }
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={e.imageUrl}
                />
              </Button>
            )
          );
        })}
        <footer>
          <Button
            disabled={!slideId}
            onClick={deleteSlideFromIntercom}
            style={{ backgroundColor: "red", color: "white" }}
          >
            {t("RemoveSlide")}
          </Button>
        </footer>
      </Modal>

      <Modal
        title={t("AttachSlide")}
        visible={showSliderModal}
        onOk={form.submit}
        onCancel={handleAddSliderCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        {slides?.map((e) => {
          return (
            <Button
              key={e.id}
              onClick={() => handleSelectedSlide(e)}
              style={slideId === e.id
                ? { width: "190px", height: "200px", margin: "20px", border: "3px solid #1677FF" }
                : { width: "190px", height: "200px", margin: "20px" }
              }
            >
              <img style={{ width: "100%", height: "100%" }} src={e.imageUrl} />
            </Button>
          );
        })}
        <Button disabled={!slideId} onClick={attachSlide}>
          {t("AddSlide")}
        </Button>
      </Modal>

      <Modal
        title={t("AddPackage")}
        visible={showPackageModal}
        onOk={form.submit}
        onCancel={handleAddPackageCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form.Item>
          {packages?.map((e) => {
            return (
              <Button
                key={e.id}
                onClick={() => handleSelectedPackage(e)}
                style={packageId === e.id
                  ? { width: "190px", height: "200px", margin: "20px", border: "3px solid #1677FF" }
                  : { width: "190px", height: "200px", margin: "20px" }
                }
              >
                <img style={{ width: "100%", height: "100%" }} src={e.imageUrl} />
                <div> {e.name} </div>
              </Button>
            );
          })}
        </Form.Item>
        <div style={{ margin: "20px" }}>
          <span style={{ marginRight: "12px" }}>{`${t("PaymentType")}:`}</span>
          <Radio.Group onChange={paymentTypeChange} value={paymentType}>
            <Space direction="horizontal">
              <Radio value={'month'}>{t("ByMonth")}</Radio>
              <Radio value={'year'}>{t("ByYear")}</Radio>
            </Space>
          </Radio.Group>
        </div>
        <Button disabled={!packageId} onClick={addPackageToIntercom}>
          {t("AddPackage")}
        </Button>
      </Modal>

      <Modal
        title={t("RemovePackage")}
        visible={showDeletePackageModal}
        onOk={form.submit}
        onCancel={handleDeletePackageCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        {packagesFromIntercom?.map((e) => {
          return (
            packagesFromIntercom?.length && (
              <Button
                key={e.id}
                onClick={() => handleSelectedPackage(e)}
                style={packageId === e.id
                  ? { width: "190px", height: "200px", margin: "20px", border: "3px solid #1677FF" }
                  : { width: "190px", height: "200px", margin: "20px" }
                }
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={e.imageUrl}
                />
                <div> {e.name} </div>
              </Button>
            )
          );
        })}
        <footer>
          <Button
            disabled={!packageId}
            onClick={deletePackageFromIntercom}
            style={{ backgroundColor: "red", color: "white" }}
          >
            {t("RemovePackage")}
          </Button>
        </footer>
      </Modal>

      <Modal
        title={t("AddPackage")}
        visible={showPackageToFlatsModal}
        onOk={form.submit}
        onCancel={handleAddPackageToFlatsCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        {packagesFromIntercom?.map((e) => {
          return (
            <Button
              key={e.id}
              onClick={() => handleSelectedPackage(e)}
              style={{ width: "190px", height: "200px", margin: "20px" }}
            >
              <img style={{ width: "100%", height: "100%" }} src={e.imageUrl} />
              <div> {e.name} </div>
            </Button>
          );
        })}
        <div style={{ margin: "20px" }}>
          <span style={{ marginRight: "12px" }}>{`${t("PaymentType")}:`}</span>
          <Radio.Group onChange={paymentTypeChange} value={paymentType}>
            <Space direction="horizontal">
              <Radio value={'month'}>{t("ByMonth")}</Radio>
              <Radio value={'year'}>{t("ByYear")}</Radio>
            </Space>
          </Radio.Group>
        </div>
        <Button disabled={!packageId} onClick={addPackageToAllFlats}>
          {t("AddPackage")}
        </Button>
      </Modal>
      <Modal
        title={t("LinkService")}
        open={isLinkServiceModalOpen}
        onOk={linkServiceToIntercom}
        onCancel={() => {
          toggleLinkServiceModal(false);
        }}
        confirmLoading={isLoading}
        okText={t("LinkService")}
        cancelText={t("Cancel")}
        width={650}
      >
        <ServicesList
          services={services}
          setSelectedServices={setSelectedServices}
        />
      </Modal>
      <Modal
        title="Unlink service to intercom (Choose only one)."
        open={isUnlinkServiceModalOpen}
        onOk={unlinkServiceFromIntercom}
        onCancel={() => {
          toggleUnlinkServiceModal(false);
        }}
        confirmLoading={isLoading}
        okText="Unlink from device"
      >
        <ServicesList
          services={services}
          setSelectedServices={setSelectedServices}
        />
      </Modal>
      <Modal
        title="User Information"
        visible={userInfoModal}
        onOk={form.submit}
        onCancel={handleUserInfoCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={handleUserInfoCancel}>
          <Form.Item
            label="Active Devices"
            name="active_devices"
            rules={[{ required: false }]}
          >
            <Typography>
              {devices.length}
            </Typography>
          </Form.Item>
          <Form.Item
            label="Active Users"
            name="active_users"
            rules={[{ required: false }]}
          >
            <Typography>
              {onlineDevices && onlineDevices.length}
            </Typography>
          </Form.Item>
          <Form.Item
            label="Registered Flats"
            name="registered_flats"
            rules={[{ required: false }]}
          >
            <Typography>
              {deviceInfo && deviceInfo['flats'] ? deviceInfo['flats'].length : 0}
            </Typography>
          </Form.Item>
          <Form.Item
            label="Flats that are online"
            name="online_status"
            rules={[{ required: false }]}
          >
            <Typography>
              {onlineDevices && onlineDevices.length}
            </Typography>
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            Ok
          </Button>
        </Form>
      </Modal>
      <TableSearchInput
        setFilterValue={setFilteredValue}
        placeHolder={t("SearchForAddress")}
      />
      <Table
        columns={columns}
        dataSource={devices.filter((el) =>
          el.address?.address
            ?.toLowerCase()
            .includes(filteredValue.toLowerCase())
        )}
        loading={isLoading}
      />
    </div>
  );
};
