import React, { useContext } from "react";
import { Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { navItems, malkhasyantsNavItems, navItemsLimited, useAppShell } from "./useAppShell";
import Sidebar from "./Sidebar";
import { CurrentUser } from "../pages/CurrentUser/CurrentUser";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/AuthContext";

const { Header, Content, Footer } = Layout;
export const AppShell = ({ children }: { children: any }) => {
  const { collapsed, onCollapse, defaultSelected } = useAppShell();
  const auth = useContext(AuthContext);
  const navItem = auth.isSuperAdmin ? navItems : (auth.authState.userInfo.id === "53714479-21b0-47c0-9b7d-101001e94ac9" || auth.authState.userInfo.id === "a3361db0-3b59-4020-8192-ba3c2052917a" || auth.authState.userInfo.id === "ffca3360-b8d4-4d88-bb97-948d839293e4" || auth.authState.userInfo.id === "09a71f42-33a6-4326-a91a-6f495e3d8b97") ? navItemsLimited : malkhasyantsNavItems;
  const { i18n, t } = useTranslation();
  const changeLanguageHandler = (e) => {
    localStorage.setItem("lng", e)
    i18n.changeLanguage(e);
  }
  const currentLng = i18n.language;
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar
        collapsed={collapsed}
        onCollapse={onCollapse}
        defaultSelected={defaultSelected}
      />
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: "0 32px 0 0",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <span
            onClick={() => changeLanguageHandler('hy')}
            style={{
              fontWeight: currentLng === 'hy' ? 'bold' : 400,
              cursor: "pointer",
              color: "#fff",
              marginRight: 10
            }}>
            ՀԱՅ
          </span>
          <span
            onClick={() => changeLanguageHandler('en')}
            style={{
              fontWeight: currentLng === 'en' ? 'bold' : 400,
              cursor: "pointer",
              color: "#fff",
              marginRight: 10
            }}>
            ENG
          </span>
          <CurrentUser />
        </Header>
        <Content style={{ margin: "16px" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            <PageHeader
              className="site-page-header"
              title={t(`${navItem[defaultSelected].label}`)}
            />
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Boon Admin ©2020 Created by Boon Dev Team
        </Footer>
      </Layout>
    </Layout>
  );
};
